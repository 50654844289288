<template>
  <div class="integrations-log font-inter pb-5">
    <div class="d-flex">
      <div class="flex-grow-1 bd-highlight">
        <BaseHeader :title="$t('views.seller.integrations.title')"></BaseHeader>
        <b-breadcrumb :items="breadcrumbItems" class="bg-white m-0 p-0"></b-breadcrumb>
      </div>
      <div class="align-content-center bd-highlight">
        <router-link to="/integracoes" class="btn-outline mt-4">{{ $t('views.seller.integrations.title') }}</router-link>
      </div>
    </div>
    <div class="divider mt-3"></div>
    <div class="d-flex my-4">
      <div class="flex-grow-1 align-content-center bd-highlight">
        <h3 class="title-main">{{ $t('views.seller.integrations.logs.title') }}</h3>
      </div>
      <div class="bd-highlight">
        <date-range-picker 
          :locale-data="locale" 
          :ranges="ranges" 
          :min-date="minDate"
          :max-date="maxDate"
          v-model="dateRange"
          @update="changeLogs"
          opens="left" 
        >
          <template>
            <div class="input-group">
              <input class="form-control" type="text" readonly />
            </div>
          </template>
        </date-range-picker>
      </div>
      <div class="bd-highlight">
        <b-button class="btn btn-filter ml-4 px-4" @click="openFilter">
          <img src="@/assets/img/icons/u_filter.svg" />
          <span class="ml-2">{{ $t('views.seller.integrations.logs.button.filter') }}</span>
        </b-button>
      </div>
    </div>
    <div class="logs">
      <div class="logs-row" v-for="(log, i) in logs" :key="`row-${log.id}-${i}`">
        <div class="d-flex flex-nowrap align-items-center logs-row__title" v-b-toggle="`collapse-${log.id}`">
          <div class="bd-highlight px-3">
            <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2.5" cy="3" r="2.5" :fill="statusColor(log.response)"/>
            </svg>
          </div>
          <div class="d-flex flex-grow-1 px-2">
            <span class="icon mr-2">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.3002 3.72916L5.58353 6.44582C5.2627 6.76666 4.7377 6.76666 4.41686 6.44582L1.7002 3.72916" :stroke="statusColor(log.response, '#475467')" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <div class="text-truncate" :class="statusLog(log.response)">
              <span class="date d-inline-block">{{ log.created_at | formatDate }}</span> - {{ getNameEvent(log.trigger) }} - {{ log.url }}
            </div>
          </div>
          <div class="logs-row__button">
            <b-button class="btn-outline logs-row__button text-nowrap" :disabled="isResendLog(log.id)" @click.stop="resendWebhook(log.id)">
              <template v-if="isResendLog(log.id)">
                <b-spinner variant="primary" class="mr-2" small></b-spinner>
                {{ $t('views.seller.integrations.logs.button.resending') }}
              </template>
              <template v-else>
                {{ $t('views.seller.integrations.logs.button.resend') }}
              </template>
            </b-button>
          </div>
        </div>
        <b-collapse :id="`collapse-${log.id}`">
          <div class="logs-show">{{ log.response_body | prettyJson }}</div>
        </b-collapse>
      </div>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <div class="logs-row" v-for="i in 15" :key="`load_${i}`">
            <div class="d-flex align-items-center logs-row__title px-3">
              <b-skeleton width="80%"></b-skeleton>
            </div>
          </div>
        </template>
      </b-skeleton-wrapper>
      <template v-if="!loading && logs.length === 0">
        <div class="logs-not-found">
          <img src="@/assets/img/icons/info-circle.svg" class="mx-auto mb-2"/>
          <p class="mx-auto text-center">
            {{ $t('views.seller.integrations.logs.no_content') }}
          </p>
        </div>
      </template>
    </div>
    <div class="mt-5 text-center" v-if="more">
      <b-button class="btn-outline" :disabled="loading" @click="fetchLogs(more)">
        <template v-if="loading">
          <b-spinner variant="primary" class="mr-2" small></b-spinner>
          {{ $t('views.seller.integrations.logs.button.loading_more') }}
        </template>
        <template v-else>
          {{ $t('views.seller.integrations.logs.button.load_more') }}
        </template>
      </b-button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import FilterLogs from "@/components/Seller/Integrations/FilterLogs.vue";
import CallbackService from "@/services/resources/CallbackService";

export default {
  name: "IntegrationsLog",
  components: {
    DateRangePicker
  },
  data: () => {
    return {
      loading: true,
      resendLog: [],
      logs: [],
      more: null,
      cursor: '',
      events: [
        {
          value: "salePaid",
          text: "Venda paga"
        },
        {
          value: "saleRefused",
          text: "Venda recusada",
        },
        {
          value: "saleRefunded",
          text: "Venda reembolsada",
        },
        {
          value: "saleChargedback",
          text: "Chargeback realizado",
        },
        {
          value: "saleUnpaid",
          text: "Venda não paga",
        },
        {
          value: "saleWaitingPayment",
          text: "Venda aguardando pagamento",
        },
        {
          value: "contractUnpaid",
          text: "Assinatura não paga",
        },
        {
          value: "contractPaid",
          text: "Assinatura ativa",
        },
        {
          value: "contractTrialing",
          text: "Assinatura em trial",
        },
        {
          value: "contractPendingPayment",
          text: "Assinatura aguardando pagamento",
        },
        {
          value: "contractEnded",
          text: "Assinatura encerrada",
        },
        {
          value: "contractCanceled",
          text: "Assinatura cancelada",
        },
        {
          value: "checkoutAbandoned",
          text: "Checkout abandonado",
        },
        {
          value: "contractExpiration",
          text: "Aviso de encerramento de contrato",
        },
        {
          value: "saleDueDays",
          text: "Aviso de vencimento de boleto para assinatura",
        }
      ],
      filter: {
        status: {
          select: [],
          use: [],
          items: [
            {
              id: "success",
              name: "Sucesso",
            }, {
              id: "error",
              name: "Error",
            }
          ]
        },
        integrations: {
          select: [],
          use: [],
          items: []
        },
      },
      dateRange: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
        endDate: new Date()
      },
      minDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      maxDate: new Date(),
      locale: {
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        weekLabel: 'W',
        daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        firstDay: 0,
      },
      ranges: {
        'Últimos 3 dias': [new Date(new Date().setDate(new Date().getDate() - 2)), new Date()],
        'Últimos 7 dias': [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()],
        'Últimos 30 dias': [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()],
      }
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Dashboard', href: '/dashboard' },
        { text: this.$t('views.seller.integrations.title'), href: '/integracoes' },
        { text: `Log de envios` }
      ];
    },
  },
  created() {
    this.fetchLogs();
    this.fetchIntegrations();
  },
  filters: {
    prettyJson(jsonText) {
      if (!jsonText) return 'Nenhum retorno recebido.';

      try {
        const jsonObj = JSON.parse(jsonText);
        return JSON.stringify(jsonObj, null, 4);
      }
      catch (e) {
        return jsonText;
      }
    },
    formatDate(date) {
      return date.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/, '$3-$2-$1 $4:$5:$6');
    }
  },
  methods: {
    fetchLogs(next = null) {
      this.loading = true;
      
      CallbackService.build().search({
        url: 'integrations/logs/all',
        min_date: this.dateRange.startDate.toISOString().split('T')[0],
        max_date: this.dateRange.endDate.toISOString().split('T')[0],
        status: this.filter.status.select.map(item => item.id),
        integrations: this.filter.integrations.select.map(item => item.id),
        cursor: this.cursor,
        page: next || 1,
      }).then(response => {
        this.loading = false;

        if (response.total > 0) {
          this.logs = [...this.logs, ...response.data];
          this.cursor = response.cursor;

          if(response.next_page_url) {
            this.more = response.current_page + 1;
          }
          else {
            this.more = null;
          }
        }
      })
      .catch(() => {
        this.loading = false;
      });
    },
    changeLogs() {
      this.logs = [];
      this.more = null;
      this.fetchLogs();
    },
    fetchIntegrations() {
      this.loading = true;
      CallbackService.build().read('integrations/all')
      .then(response => {
        this.filter.integrations.items = response;
      })
      .catch(() => {
      });
    },
    getNameEvent(event) {
      return this.events.find(item => item.value === event)?.text || (event == '' ? this.$t('views.seller.integrations.logs.not_event') : event);
    },
    statusLog(response) {

      if(response?.statusCode < 300 && response?.statusCode >= 200) {
        return 'success';
      }

      return 'error';
    },
    statusColor(response, successColor = '#16A34A') {

      const status = this.statusLog(response);

      if(status === 'error') {
        return '#DB242D';
      }

      return successColor;
    },
    openFilter() {
      Vue.swal({
        title: this.$t('views.seller.integrations.logs.alert.filter.title'),
        html: '<div id="filter-logs"></div>',
        width: 600,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: this.$t('views.seller.integrations.logs.alert.filter.ok'),
        cancelButtonText: this.$t('views.seller.integrations.logs.alert.filter.cancel'),
        customClass: "sweet-container-new sweet-container-new__type_filter",
        confirmButtonClass: "btn btn-primary btn-xl text-nowrap",
        cancelButtonClass: "btn btn-outline-secondary btn-xl text-nowrap",
        buttonsStyling: false,
        reverseButtons: true,
        onOpen: () => {
          new Vue({
            el: '#filter-logs',
            render: h => h(FilterLogs, {
              props: {
                valueStatus: this.filter.status.select,
                listStatus: this.filter.status.items,
                valueIntegrations: this.filter.integrations.select,
                listIntegrations: this.filter.integrations.items,
                translate: {
                  statusTitle: this.$t('views.seller.integrations.logs.alert.filter.status.title'),
                  title: this.$t('views.seller.integrations.logs.alert.filter.integrations.title'),
                  selectAll: this.$t('views.seller.integrations.logs.alert.filter.select_all'),
                  removeAll: this.$t('views.seller.integrations.logs.alert.filter.remove_all'),
                  search: this.$t('views.seller.integrations.logs.alert.filter.integrations.search'),
                  selectStatus: this.$t('views.seller.integrations.logs.alert.filter.status.select_status'),
                  noContent: this.$t('views.seller.integrations.logs.alert.filter.status.no_content'),
                  selectIntegrations: this.$t('views.seller.integrations.logs.alert.filter.integrations.select_status'),
                  noContentIntegrations: this.$t('views.seller.integrations.logs.alert.filter.integrations.no_content')
                }
              },
              on: {
                'status': (newValue) => {
                  this.filter.status.select = newValue;
                },
                'integrations': (newValue) => {
                  this.filter.integrations.select = newValue;
                },
              }
            })
          });
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeLogs();
        }
      });
    },
    isResendLog(id) {
      return this.resendLog.includes(id);
    },
    resendWebhook(id) {
      this.resendLog.push(id);
      CallbackService.build().update({
        id: `integrations/logs/${id}`,
      }).then(() => {
        this.$bvToast.toast(this.$t('views.seller.integrations.logs.toast.success.message'), {
            title: this.$t('views.seller.integrations.logs.toast.success.title'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
        });
      })
      .finally(() => {
        this.resendLog = this.resendLog.filter(item => item !== id);
      });
    }
  }
}
</script>

<style lang="scss">
.integrations-log {

  .daterangepicker.opensleft {
    top: 56px !important;
    right: 0 !important;
  }

  .logs {
    border: solid 1px #D7DAE2;
    border-radius: 8px;
    
    &-row {
      border-bottom: solid 1px #D7DAE2;
      position: relative;
      overflow: hidden;

      &__title {
        height: 34px;
        font-size: 12px;
        
        .text-truncate {
          max-width: calc(100vw - 380px);
        }

        .icon {
          position: relative;
          transition: all .2s ease;
          display: inline-block;
          top: -2px;
        }

        .date {
          width: 120px;
        }

        &.not-collapsed .icon {
          transform: rotate(-180deg);
          top: 2px;
        }

        .error,
        .error .date {
          color: #DB242D;
        }
      }

      &__button {
        position: absolute;
        transition: all .5s ease;
        top: 20px;
        right: 4px;
        opacity: 0;
      }


      &__title.not-collapsed .logs-row__button {
        top: 4px;
        opacity: 1;
        z-index: 1;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &-show {
      padding: 32px 70px;
      white-space: pre-wrap;
      font-size: 12px;
    }

    &-not-found {
      padding: 110px 40px;

      p {
        font-size: 16px;
        max-width: 520px;
        line-height: 1.5;
      }
    }
  }
}
</style>