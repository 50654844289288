<template>
  <div class="filter-logs">
    <div>
      <div class="clearfix mt-4">
        <strong class="title float-left">{{ translate.statusTitle }}</strong>
        <a href="#" @click.prevent="selectAll('status')" class="select-all float-right">
          {{ status?.value?.length > 0 ? translate.removeAll : translate.selectAll }}
        </a>
      </div>
      <multiselect 
        v-model="status.value" 
        :options="status.items" 
        :multiple="true" 
        :taggable="false"
        :show-labels="false" 
        :clear-on-select="false" 
        :close-on-select="false"
        :limit-text="limitText" 
        :limit="4"
        :searchable="false"
        class="new-style"
        :placeholder="translate.search"
        track-by="name"
        label="name"
      >
        <template #option="props">
          <span class="d-flex option__item">
            <img :src="isOptionSelected('status', props.option.id)" />
            {{ props.option.name }}
          </span>
        </template>
        <template #placeholder>
          <span class="select-title">{{ translate.selectStatus }}</span>
        </template>
        <span slot="noResult">{{ translate.noContent }}</span>
        <div slot="limit" class="multiselect__tag limit">+{{ status?.value?.length - 4 }}</div>
        <template #caret>
          <img src="@/assets/img/icons/multiselect-arrow.svg" class="multiselect__arrow" />
        </template>
      </multiselect>
    </div>
    <div>
      <div class="clearfix mt-4">
        <strong class="title float-left">{{ translate.title }}</strong>
        <a href="#" @click.prevent="selectAll('integrations')" class="select-all float-right">
          {{ integrations?.value?.length > 0 ? translate.removeAll : translate.selectAll }}
        </a>
      </div>
      <multiselect 
        v-model="integrations.value" 
        :options="integrations.items" 
        :multiple="true" 
        :taggable="false"
        :show-labels="false" 
        :clear-on-select="false" 
        :close-on-select="false"
        :limit-text="limitText" 
        :limit="4"
        :searchable="true"
        class="new-style"
        :placeholder="translate.search"
        track-by="name"
        label="name"
      >
        <template #option="props">
          <span class="d-flex option__item">
            <img :src="isOptionSelected('integrations', props.option.id)" />
            {{ props.option.name }}
          </span>
        </template>
        <template #placeholder>
          <span class="select-title">{{ translate.selectIntegrations }}</span>
        </template>
        <span slot="noResult">{{ translate.noContentIntegrations }}</span>
        <div slot="limit" class="multiselect__tag limit">+{{ integrations?.value?.length - 4 }}</div>
        <template #caret>
          <img src="@/assets/img/icons/multiselect-arrow.svg" class="multiselect__arrow" />
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "FilterLogs",
  components: {
    Multiselect
  },
  props: {
    valueStatus: {
      type: Array,
      default: () => []
    },
    listStatus: {
      type: Array,
      default: () => []
    },
    valueIntegrations: {
      type: Array,
      default: () => []
    },
    listIntegrations: {
      type: Array,
      default: () => []
    },
    translate: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      status: {
        value: [],
        items: [],
      },
      integrations: {
        value: [],
        items: [],
      }
    }
  },
  mounted() {
    this.status.value = (this.valueStatus.length > 0) ? this.valueStatus : this.listStatus;
    this.status.items = this.listStatus;
    this.integrations.value = (this.valueIntegrations.length > 0) ? this.valueIntegrations : this.listIntegrations;
    this.integrations.items = this.listIntegrations;
  },
  watch: {
    'status.value': {
      deep: true,
      handler() {
        this.emitValues();
      }
    },
    'integrations.value': {
      deep: true,
      handler() {
        this.emitValues();
      }
    },
  },
  methods: {
    limitText(count) {
      return `+ ${count}`
    },
    selectAll(type) {
      if (this[type]?.value?.length > 0) {
        this[type].value = [];
      } else {
        this[type].value = this[type].items;
      }
    },
    isOptionSelected(type, option, force = false) {
      return this[type]?.value?.find(item => item.id === option) || force
        ? require('@/assets/img/icons/checkbox-area-selected.svg')
        : require('@/assets/img/icons/checkbox-area.svg');
    },
    emitValues() {
      this.$emit('status', this.status.value);
      this.$emit('integrations', this.integrations.value);
    }
  }
}
</script>

<style lang="scss">
.filter-logs {

  .title {
    font-weight: 600;
    font-size: 14px;
    display: block;
    color: #111827;
    transition: all .2s ease;
  }

  .select-all {
    font-size: 12px;
    color: #949494;
    text-decoration: underline;
  }
}
</style>
